import React from 'react'
//Dont import design system here, this is loaded first to get something on screen. the rest of the app is loaded in after

import PxLogo from '../PxLogo'

const SplashScreen = ({ message }) => {
    return (
        <div style={{ position: "absolute", display: "flex", top: 0, left: 0, right: 0, bottom: 0 }}>
            <div style={{ margin: "auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
                {/* Inline due to an issue with Chrome reloading the svg when styling is loaded, causing a flashing effect */}
                <PxLogo width="150px" />
                {
                    !!message &&
                    <span style={{ display: "block", marginTop: "16px", fontFamily: "Karla" }}>{message}</span>
                }
            </div>
        </div>
    )
}

export default SplashScreen