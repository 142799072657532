import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import GlobalStyles from 'helpers/global-styles'
import SplashScreen from 'components/SplashScreen'
import * as serviceWorker from './serviceWorker';
import IncompatableBrowserScreen from 'components/IncompatableBrowserScreen'
const App = React.lazy(() => import('./App'));

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Fragment>
        <GlobalStyles />
        <IncompatableBrowserScreen render={() => (
            <Suspense fallback={<SplashScreen />}>
                <App />
            </Suspense>
        )}/>
    </Fragment>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
